export function initTheme() {
  const toggleSwitches = Array.from(document.getElementsByClassName('theme-switch')) as HTMLElement[];
  if (toggleSwitches.length === 0) {
    console.warn("Dark mode toggle not initialized. Didn't find `.theme-switch` elements.");
    return;
  }
  const currentTheme = localStorage.getItem('theme') ?? 'light';

  const element = document.querySelector(`.theme-switch[data-theme="${currentTheme}"]`);
  element?.classList.toggle("active");
  document.documentElement.setAttribute('data-theme', currentTheme);

  function switchTheme(event: Event) {
    const target = event.target as HTMLElement;
    const switcher = target.closest(".theme-switch") as HTMLElement;
    const index = toggleSwitches.indexOf(switcher);
    switcher.classList.toggle("active");
    const nextSwitch = toggleSwitches[(index + 1) % 2];
    nextSwitch.classList.toggle("active");
    const nextTheme = nextSwitch.dataset.theme as string;
    document.documentElement.setAttribute('data-theme', nextTheme);
    localStorage.setItem('theme', nextTheme);
  }

  toggleSwitches.forEach(function (element) {
    element.addEventListener('click', switchTheme, false);
  });
}
