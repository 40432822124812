import Cookies from "js-cookie";
import htmx from "htmx.org/dist/htmx.esm";
import _hyperscript from 'hyperscript.org';


import {initializeCheckout} from "../components/checkout";
import {sendEvent} from "../components/event";
import {initTheme} from "../components/theme";

function hideElement(elementId: string) {
  let el = document.getElementById(elementId);
  if (el) {
    el.style.display = "none";
    el.dispatchEvent(new CustomEvent("elementClosed", {}));
  }
}

function showLoginModal() {
  let modal = document.getElementById("login-modal");
  if (modal) {
    modal.style.display = "flex";
  }
}

function triggerCustomEvent(element: HTMLElement, eventName: string, customData: object) {
  const event = new CustomEvent(eventName, {
    detail: customData, // Add custom data here
  });
  element.dispatchEvent(event);
}

function updatePlaceholder() {
  const input = document.getElementById("suggest") as HTMLInputElement;
  if (window.innerWidth > 450) {
    input.placeholder = "Search title, authors, genres";
  } else {
    input.placeholder = "Search";
  }
}


// make the hover nav popovers more robust
function makeNavRobust() {
  document.querySelectorAll('.nav-hover').forEach((item) => {
    let timeout: number;

    item.addEventListener('mouseenter', () => {
      const target = item.querySelector('.nav-hover-target') as HTMLElement;
      clearTimeout(timeout);
      target.classList.remove("opacity-0", "pointer-events-none");
      target.classList.add("opacity-100", "pointer-events-auto");
    });

    item.addEventListener('mouseleave', () => {
      const child = item.querySelector('.nav-hover-target') as HTMLElement;
      timeout = setTimeout(() => {
        child.classList.remove("opacity-100", "pointer-events-auto");
        child.classList.add("opacity-0", "pointer-events-none");
      }, 500); // Adjust delay
      // when we leave the shown element itself we hide right away
      child.addEventListener('mouseleave', () => {
        clearTimeout(timeout);
        child.classList.remove("opacity-100", "pointer-events-auto");
        child.classList.add("opacity-0", "pointer-events-none");
      });
    });
  });
}


window.showLoginModal = showLoginModal;
window.hideElement = hideElement;
window.triggerCustomEvent = triggerCustomEvent;
window.getCookie = Cookies.get;
window.initializeCheckout = initializeCheckout;
window.sendEvent = sendEvent;
window.addEventListener("resize", () => {
  updatePlaceholder();
});

window.htmx = htmx;
window.addEventListener('load', () => {
  initTheme();
  updatePlaceholder();
  makeNavRobust();
  _hyperscript.browserInit();
});
